<template>
  <div class="mine_center">
    <div class="to_notice" center_bgc flex>
      <p class="word_tilte" flex column center align>
        <i class="iconfont icon-gonggao1"></i>
        <span>公</span>
        <span>告</span>
      </p>
      <!-- <div class="word_swiper"> -->
      <div
        class="swiper-container1"
        id="swiper_container1"
        v-if="person_info.notices && person_info.notices.length != 0"
      >
        <div
          class="swiper-wrapper"
          v-if="person_info.notices && person_info.notices.length != 0"
        >
          <div
            class="swiper-slide"
            v-for="item in person_info.notices"
            :key="item.id"
            v-show="item.text != ''"
            flex
            align
          >
            <p
              book_room_intro
              words-hidden
              :data-row="`${JSON.stringify(item)}`"
            >
              {{ item.text }}
            </p>
            <span class="mine_notice_border"></span>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <div class="mine_msg" center_bgc>
      <div class="msg_top">
        <div class="msg_top_item">
          <span class="top_item_num">{{ person_info.concerns }}</span>
          <span flex class="top_item_icon" center>
            <span
              class="iconfont icon-guanzhu2"
              style="color: rgb(255, 113, 113)"
            ></span>
            <span>关注</span>
          </span>
        </div>
        <div class="msg_top_item">
          <span class="top_item_num">{{ person_info.byconcerns }}</span>
          <span flex class="top_item_icon" center>
            <span
              class="iconfont icon-fensi"
              style="color: rgb(248, 150, 152)"
            ></span>
            <span>粉丝</span>
          </span>
        </div>
        <div class="msg_top_item" v-if="open_relation()">
          <span class="top_item_num">{{ person_info.masters }}</span>
          <span flex class="top_item_icon" center>
            <span
              class="iconfont icon-laoshi"
              style="color: rgb(173, 129, 245)"
            ></span>
            <span>已认师父</span>
          </span>
        </div>
        <div class="msg_top_item" v-if="open_relation()">
          <span class="top_item_num">{{ person_info.students }}</span>
          <span flex class="top_item_icon" center>
            <span
              class="iconfont icon-xuesheng"
              style="color: rgb(23, 171, 227)"
            ></span>
            <span>已认徒弟</span>
          </span>
        </div>
      </div>
      <div class="msg_btm">
        <div class="msg_btm_item level_left">
          <div class="btm_item_top">
            <span>付费等级</span>
            <span class="go_cost" @click="get_money">充 值</span>
          </div>
          <div
            :class="is_vip ? (lev_num ? 'level_2' : 'level') : 'level'"
            @click="get_money"
          >
            <i class="iconfont icon-huiyuandengji"></i>
            <span
              v-text="is_vip ? (lev_num ? lev_num : '暂无') : '暂无'"
            ></span>
          </div>
        </div>
        <div class="msg_btm_item">
          <div class="btm_item_top">
            <span>账户余额</span>
            <span class="go_cost" @click="get_money">充 值</span>
          </div>
          <div :class="Number(balance) === 0.0 ? 'level' : 'level_1'">
            <i
              class="iconfont"
              :class="
                Number(balance) === 0.0
                  ? 'icon-jinbi1'
                  : 'icon-tubiaozhizuomoban'
              "
            ></i>
            <span>{{ balance }}</span
            >书币
          </div>
        </div>
      </div>
    </div>

    <div class="mine_books_shelf" center_bgc>
      <div flex between align class="shelf_title">
        <span>我的书架</span>
        <span @click="my_brack">更多 ></span>
      </div>
      <div class="shelf_books">
        <el-table
          :data="tableData"
          style="width: 100%"
          :cell-style="cell_style"
        >
          <el-table-column
            type="cover_img"
            label="封面"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <div class="cover_img_box">
                <div class="rank_one">
                  <img
                    v-if="scope.row.cover_image"
                    :src="scope.row.cover_image"
                    alt=""
                    class="cover_img"
                    @click="to_bookinfo(scope.row.id)"
                  />
                  <img
                    v-else
                    src="../../assets/images/default.png"
                    alt=""
                    class="cover_img"
                    @click="to_bookinfo(scope.row.id)"
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="章节" align="center">
            <template slot-scope="scope">
              <div class="out_box">
                <div class="shelf_item">
                  <span
                    class="shelf_item_top shelf_item_name"
                    @click="to_bookinfo(scope.row.id)"
                    >{{ scope.row.name }}</span
                  >
                  <span class=""
                    ><span
                      class="shelf_item_author"
                      @click="
                        scope.row.author_id
                          ? to_authorinfo(scope.row.author_id)
                          : ''
                      "
                      :class="
                        scope.row.author_id ? 'intro_author' : 'un_author'
                      "
                      >{{ scope.row.author }}</span
                    >
                    著
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="new_push" label="更新" align="center">
            <template slot-scope="scope">
              <div class="out_box">
                <div class="shelf_item">
                  <span class="shelf_item_top"
                    >{{ scope.row.update_time }}更新</span
                  >
                  <span
                    class="shelf_item_btm shelf_item_chapter"
                    @click="to_read(scope.row.id, scope.row.update_page)"
                    >{{ scope.row.update_title }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="read" label="读至" align="center">
            <template slot-scope="scope">
              <div class="out_box">
                <div class="shelf_item">
                  <span
                    class="shelf_item_top"
                    v-text="scope.row.title ? '读至' : '尚未阅读'"
                  ></span>
                  <span
                    v-if="scope.row.section_status && scope.row.title"
                    class="shelf_item_btm shelf_item_chapter"
                    @click="to_read(scope.row.id, scope.row.page)"
                    >{{ scope.row.title }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { Moneyinfo, Mybook, Personcenter } from "@/https/mine_center.js";
export default {
  name: "mine_center",
  data() {
    return {
      lev_num: "",
      balance: "",
      is_vip: "",
      tableData: [
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
      ],
      person_info: {},
      timer: null,
      cell_style: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.get_level();
    this.get_limit_brack();
    this.get_personinfo();
  },
  async mounted() {
    await this.$nextTick(() => {
      this.init_swiper();
    });
  },
  methods: {
    // 初始化swiper

    init_swiper() {
      let that = this;
      this.timer = setTimeout(() => {
        var mySwiper1 = new Swiper(".swiper-container1", {
          // loop: true, //循环播放
          // slidesPerView: 3,
          // spaceBetween: 0, //滑块间隔
          // direction: "horizontal",
          // autoplay: {
          //   delay: 3000,
          //   stopOnLastSlide: false,
          //   disableOnInteraction: false,
          //   // reverseDirection: true,//反向
          // }, //自动播放
          // noSwiping: true, //禁止手动滑动
          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: false, //修改swiper的父元素时，自动初始化swiper
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          slidesPerView: 3,
          spaceBetween: 0, //滑块间隔
          loop: true, //循环播放
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }, //自动播放
          speed: 2000,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,
          on: {
            click: function (e) {
              let row = JSON.parse(e.target.getAttribute("data-row"));
              that.show_photo(row.type, row.url, row.id);
            },
          },
        });
        var container = document.getElementById("swiper_container1");
        container.onmouseenter = function () {
          mySwiper1.autoplay.stop();
        };
        container.onmouseleave = function () {
          mySwiper1.autoplay.start();
        };
      }, 500);
    },
    // 更多去到我的书架
    my_brack() {
      this.$router.push("/mine_shelf");
    },
    // 获取会员等级以及余额信息
    get_level() {
      Moneyinfo().then((res) => {
        this.lev_num = res.data.vip;
        this.balance = res.data.book_coin;
        this.is_vip = res.data.is_vip;
      });
    },
    // 获取书架信息
    get_limit_brack() {
      Mybook().then((res) => {
        if (res.data.data && res.data.data.length > 4) {
          for (let i = 0; i < 4; i++) {
            this.tableData.push(res.data.data[i]);
          }
        } else {
          this.tableData = res.data.data;
        }
      });
    },
    // 点击章节阅读
    to_read(book_id, page_id) {
      sessionStorage.setItem("book_id", book_id);
      const { href } = this.$router.resolve({
        path: "/read_book",
        query: {
          id: book_id,
          page: page_id,
        },
      });
      window.open(href, "_blank");
    },
    // 点击书籍名称或图片查看书籍详情
    to_bookinfo(id) {
      sessionStorage.setItem("book_id", id);
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 点击作者查看作者详情
    to_authorinfo(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 获取用户信息
    get_personinfo() {
      Personcenter().then((res) => {
        this.person_info = res.data;
        let notice = this.person_info.notices;
        let obj_item = [];
        notice.forEach((item) => {
          if (item.text || item.url) {
            obj_item.push(item);
          }
        });
        this.person_info.notices = obj_item;
      });
    },
    // 公告跳转
    show_photo(type, url, id) {
      console.log("mine_364", type, url, id);
      if (url) {
        if (type === 2) {
          // const { link }= this.$router.resolve({
          //   path: "/show_photo",
          //   query: {
          //     id: id,
          //   },
          // });
          // window.open(link, "_blank");
          const { href } = this.$router.resolve({
            path: "/show_photo",
            query: {
              id: id,
            },
          });
          window.open(href, "_blank");
        } else {
          window.open(url, "_blank");
        }
      }
    },
    // 充值、续费去充值中心
    get_money() {
      let mine_menu = {
        link: "/recharge",
        name: "充值中心",
        id: 7,
        icon: "icon-caiwuzhongxin",
      };
      this.$store.commit("set_mine_menu", mine_menu);

      this.$router.push({
        path: "/recharge",
      });
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped lang="scss">
@import "../../utils/tool.scss";
// @import "../../../public/static/swiper.min.css";
// @include flex-center();
.mine_center {
  background: transparent;
  display: flex;
  flex-direction: column;
  // box-shadow: none;
}
.to_notice {
  width: 100%;
  height: 100px;
  background-color: #fff;
  overflow: hidden;
  font-size: 14px;
  padding: 10px 0;
  border-radius: 0 0 4px 4px;
  .word_tilte {
    @include flex-center();
    flex-direction: column;
    width: 50px;
    padding: 10px;
    border-right: 1px solid #e4e4e4;
    > i {
      color: #f99715;
    }
  }
  .swiper-container1 {
    width: 93%;
    height: 80px;
    overflow: hidden;
    .swiper-slide {
      width: 33.333%;
      height: 80px;
      padding-left: 20px;
      text-align: left;
      & p:nth-of-type(1) {
        width: 100%;
        cursor: pointer;
        color: #333;
        font-size: 14px;
      }
      & p:nth-of-type(1):hover {
        color: rgb(49, 141, 254);
      }
      .mine_notice_border {
        display: inline-block;
        width: 0;
        height: 60%;
        border-right: 0.5px solid #eeeeee;
        margin-left: 15px;
      }
    }
  }
}
// .to_notice {
//   width: 100%;
//   display: flex;
//   padding: 15px;
//   // border: 1px solid #e4e4e4;
//   border-radius: 4px;
//   box-shadow: 0 0 5px #e4e4e4;
//   .word_tilte {
//     @include flex-center();
//     flex-direction: column;
//     width: 50px;
//     padding: 10px;
//     border-right: 1px solid #e4e4e4;
//     > i {
//       color: #f99715;
//     }
//   }
//   .word_swiper {
//     height: 75px;
//     width: calc(100% - 50px);
//     padding-left: 10px;
//     overflow: hidden;
//     .swiper-container1 {
//       width: 100%;
//       height: 75px;
//       .swiper-wrapper {
//         // flex-direction: column;
//         width: 100%;
//         .swiper-slide {
//           height: 75px;
//           width: 34%;
//           padding-left: 25px;
//           text-align: left;
//           & p:nth-of-type(1) {
//             width: 100%;
//             cursor: pointer;
//             color: #333;
//             font-size: 14px;
//           }
//           & p:nth-of-type(1):hover {
//             color: rgb(49, 141, 254);
//           }
//           .mine_notice_border {
//             width: 0;
//             height: 70%;
//             margin-left: 15px;
//             display: inline-block;
//             border-right: 1px solid #eeeeee;
//           }
//         }
//       }
//     }
//   }
// }
.mine_msg {
  margin-top: 30px;
  width: 100%;
  // border: 1px solid #e4e4e4;
  border-radius: 4px;
  // box-shadow: 0 0 5px #e4e4e4;
  @include flex-column();
  .msg_top {
    @include flex-row();
    border-bottom: 1px solid #e4e4e4;
    .msg_top_item {
      flex: 1;
      height: 100px;
      @include flex-column-center();
      .top_item_num {
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
      }
      .top_item_icon {
        min-width: 75px;
        .iconfont {
          margin-right: 5px;
          font-size: 18px;
        }
      }
    }
  }
  .msg_btm {
    @include flex-row();
    .msg_btm_item {
      flex: 1;
      height: 150px;
      @include flex-column-center();
      .btm_item_top {
        span {
          padding: 0 20px;
        }
        .go_cost {
          color: #409eff;
          cursor: pointer;
          border-left: 1px solid #e4e4e4;
        }
      }
      .level {
        padding-top: 10px;
        font-size: 14px;
        line-height: 50px;
        color: #ccc;
        cursor: pointer;
        > i,
        > span {
          color: #ccc;
        }
        span {
          font-size: 16px;
          font-weight: bold;
          padding: 5px;
        }
        .iconfont {
          font-size: 18px;
        }
      }
      .level_1 {
        padding-top: 10px;
        font-size: 14px;
        line-height: 50px;
        cursor: pointer;
        > i,
        > span {
          color: #fc9c0e;
        }
        > span {
          font-size: 22px;
          font-weight: bold;
          padding: 5px;
        }
        .iconfont {
          font-size: 22px;
        }
      }
      .level_2 {
        padding-top: 10px;
        font-size: 14px;
        line-height: 50px;
        > i,
        > span {
          color: #f9993b;
        }
        > span {
          font-size: 22px;
          font-weight: bold;
          padding: 5px;
        }
        .iconfont {
          font-size: 22px;
        }
      }
    }
    .level_left {
      border-right: 1px solid #e4e4e4;
    }
  }
}

.mine_books_shelf {
  margin-top: 30px;
  // border: 1px solid #e4e4e4;
  // box-shadow: 0 0 5px #e4e4e4;
  border-radius: 4px;
  .shelf_title {
    line-height: 30px;
    padding: 10px 30px;
    & span:nth-of-type(1) {
      font-size: 16px;
    }
    & span:nth-of-type(2) {
      cursor: pointer;
    }
    & span:nth-of-type(2):hover {
      color: #409eff;
    }
  }
  .cover_img_box {
    width: 100%;
    @include flex-center();
    .cover_img {
      width: 88px;
      height: 124px;
      cursor: pointer;
    }
  }
  .rank_one {
    width: 88px;
    height: 124px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;
    img {
      cursor: pointer;
      width: 88px;
      height: 124px;
      transition: all 0.5s;
    }
    img:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  .out_box {
    width: 100%;
    @include flex-center();
    color: #999;
  }
  .shelf_item {
    @include flex-column-center();
    line-height: 30px;
  }
  .shelf_item_btm {
    cursor: pointer;
  }
  // .shelf_item_btm:hover {
  //   color: #409eff;
  // }
  .shelf_item_chapter {
    color: #0c2962;
    cursor: pointer;
  }
  .shelf_item_name {
    cursor: pointer;
    color: #333;
    font-size: 14px;
  }
  .shelf_item_name:hover,
  .shelf_item_chapter:hover {
    color: #409eff;
  }
  .intro_author {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    color: #666;
  }
  .intro_author:hover {
    color: rgba(79, 156, 251, 1);
  }
  .un_author {
    font-size: 12px;
    font-weight: normal;
    color: #666;
  }
  // .shelf_item_author:hover {
  //   color: #409eff;
  // }
}
/deep/.el-table__header-wrapper {
  display: none;
}
/deep/ .cell {
  text-align: center;
}
</style>
